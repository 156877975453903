import { Application } from "@hotwired/stimulus";
import Carousel from '@stimulus-components/carousel'
import { Navigation } from 'swiper/modules';

import 'swiper/css/bundle';

export class AnnounceCarouselController extends Carousel {
  connect() {
    super.connect()
  }

  next() {
    this.swiper.slideNext();
  }

  prev() {
    this.swiper.slidePrev();
  }

  get defaultOptions() {
    return {
      modules: [Navigation],
      loop: true,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    }
  }
}

const application = Application.start();
application.register("announce-carousel", AnnounceCarouselController);
